/**
 * Return the union of the two permission arrays
 * @param {string[]} permissions
 * @param {string[]} otherPermissions
 */
export function checkPermissionIntersection(permissions, otherPermissions) {
    return permissions.filter((permissionName) =>
        otherPermissions.includes(permissionName),
    );
}

/**
 * Check whether the permissions match
 * @param {string[]} userPermissions
 * @param {string[]} permissions
 * @param {VIEW_TYPE_PIPES|VIEW_TYPE_PARTS} [checkViewType]
 * @returns string[]
 */
export function ownedPermissions(userPermissions, permissions, checkViewType) {
    const relevantPermissions = checkPermissionIntersection(
        userPermissions,
        permissions,
    );
    if (checkViewType) {
        const prefix = `@${checkViewType}_`; // sync up with models.py has_access_permissions
        return relevantPermissions.filter((permissionName) =>
            permissionName.includes(prefix),
        );
    }
    return relevantPermissions;
}

/**
 * Check whether the current user has at least one valid permission
 * @param {AuthenticatedUser} [user]
 * @param {string[]} permissions
 * @param {VIEW_TYPE_PIPES|VIEW_TYPE_PARTS} [checkViewType]
 */
export function checkPermissions(user, permissions, checkViewType) {
    if (!user) {
        return false;
    }

    const relevantPermissions = ownedPermissions(
        user.access_permissions,
        permissions,
        checkViewType,
    );
    return relevantPermissions.length > 0;
}
