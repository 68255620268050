import loadable from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { Provider } from 'react-redux';

import createReduxStore from 'core/store';
import api from 'core/utils/api';
import { DJConstProvider } from 'core/components/DJConstContext';
import rootSaga from 'core/sagas';
import { VIEW_TYPE_PARTS } from 'accounts/constants';
import { AutoLogoutWrapper } from './core/components/AutoLogout';
import { ConnectionStatus } from './core/components';

Sentry.init({
    dsn: DJ_CONST.SENTRY_DSN,
    release: DJ_CONST.SENTRY_RELEASE,
    environment: `${DJ_CONST.SENTRY_ENVIRONMENT}-JS`,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Adjust the sample rate based on your traffic
    tracesSampleRate: 0.1,
    // Limit replay sessions
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    // Prevent excessive data transmission
    maxRequestBodySize: 'medium',
    maxValueLength: 1000,
});

if (DJ_CONST.user) {
    Sentry.setUser({
        id: DJ_CONST.user.id,
        email: DJ_CONST.user.email,
        name: DJ_CONST.user.name,
    });
}

moment.locale('et');

/* eslint-disable quote-props */
/*
    routeMap expects:
    key (Django urlconf name e.g. qa:home): {
        key (standard CSS-syntax, will render to all matching elements): Module imported via loadable.
    }

    > Note: To provide a reducer for the module please export it under the name `reducer` from
    >  the lazily imported component file.
 */
const sharedRouteMap = {
    react_elements: {
        '#react-container': loadable(() =>
            import('core/components/ReactElements'),
        ),
    },
};
const pipeRouteMap = {
    ...sharedRouteMap,
    'qa:home': {
        '#react-container': loadable(() => import('pages/pipes/PipesQrsPage')),
    },
    'qa:timeline': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesQrsTimelinePage'),
        ),
    },
    'cooling:home': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesCoolingPage'),
        ),
    },
    'orders:home': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesOrdersPage'),
        ),
    },
    'operations:home': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesOperationsPage'),
        ),
    },
    'prod:timeplan': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesTimeplanPage'),
        ),
    },
    'reports:home': {
        '#react-container': loadable(() =>
            import('pages/pipes/PipesReportsPage'),
        ),
    },
};
const partRouteMap = {
    ...sharedRouteMap,
    'orders:home': {
        '#react-container': loadable(() =>
            import('pages/parts/PartsOrdersPage'),
        ),
    },
    'orders:sales-tool': {
        '#react-container': loadable(() =>
            import('pages/parts/PartsSalesToolPage'),
        ),
    },
    'prod:timeplan': {
        '#react-container': loadable(() =>
            import('pages/parts/PartsTimeplanPage'),
        ),
    },
    'operations:home': {
        '#react-container': loadable(() =>
            import('pages/parts/PartsOperationsPage'),
        ),
    },
    'configurations:part-templates': {
        '#react-container': loadable(() =>
            import('pages/parts/PartsTemplatesPage'),
        ),
    },
};
/* eslint-enable quote-props */

/**
 * Do manual initialization of global elements.
 *
 * Currently used to avoid including the whole of JQuery for some bootstrap elements.
 */
function initGlobalJs() {
    /** @param {Element} el */
    const toggleOpen = (el, key) => {
        if (el.classList.contains(key)) {
            el.classList.remove(key);
        } else {
            el.classList.add(key);
        }
    };

    /** @type {HTMLCollectionOf<Element>} */
    const dropDowns = document.getElementsByClassName('dropdown');
    for (let i = 0; i < dropDowns.length; i++) {
        const el = dropDowns[i];
        el.onclick = () => toggleOpen(el, 'open');
    }

    /** @type {HTMLCollectionOf<Element>} */
    const collapseElements = document.getElementsByClassName('collapse');
    for (let i = 0; i < collapseElements.length; i++) {
        const el = collapseElements[i];
        document
            .querySelectorAll(`[data-target="#${el.id}"]`)
            .forEach((trigger) => {
                trigger.onclick = () => toggleOpen(el, 'show'); // eslint-disable-line
            });
    }
}

function initNavbar() {
    if (DJ_CONST.config.VIEW_SWITCH_ACTIVE) {
        /**
         * @param {Event} e
         */
        const setOpenView = (e) => {
            /** @type {NamedNodeMap} */
            const nnm = e.target.attributes;
            const toOpen = nnm.getNamedItem('data-to-open').value;
            api.openView
                .post(null, { to_open: toOpen })
                .then(() => window.location.replace('/'));
        };

        /** @type {HTMLCollectionOf<Element>} */
        const openGlobalViewChoices = document.getElementsByClassName(
            'open-global-view',
        );
        for (let i = 0; i < openGlobalViewChoices.length; i++) {
            openGlobalViewChoices[i].onclick = setOpenView;
        }
    }
}

const defaultConfig = {
    '#react-container': loadable(() => import('pages/EmptyPage')),
};

/**
 * @param {String} routeName
 */
function init(routeName) {
    initNavbar();
    initGlobalJs();

    const routeMap =
        DJ_CONST.open_view === VIEW_TYPE_PARTS ? partRouteMap : pipeRouteMap;
    const configs = routeMap[routeName] ?? defaultConfig;

    const stores = {};

    Object.keys(configs).forEach((selector) => {
        const loadableComponent = configs[selector];
        const matches = document.querySelectorAll(selector);

        // people don't like this hack because other people are dumb
        // don't be dumb - learn javascript before complaining about it on the internet
        Array.prototype.forEach.call(matches, (elem) => {
            loadableComponent.load().then((exported) => {
                const Component = exported.default;
                const { reducer } = exported;
                if (!reducer) {
                    // Note this is for when (in the future, currently not used, if we want to render a component without redux)
                    ReactDOM.render(
                        <DJConstProvider djConst={DJ_CONST}>
                            <Component />
                        </DJConstProvider>,
                        elem,
                    );
                    return;
                }

                const { store, sagaMiddleware } = createReduxStore(reducer);
                sagaMiddleware.run(rootSaga);

                stores[`${routeName}-${selector}`] = store;

                ReactDOM.render(
                    <Provider store={store}>
                        <DJConstProvider djConst={DJ_CONST}>
                            <ConnectionStatus />
                            <Component />
                            <AutoLogoutWrapper />
                        </DJConstProvider>
                    </Provider>,
                    elem,
                );
            });
        });
    });

    return stores;
}

export { init };
