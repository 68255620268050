import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from '../core/utils/cookie';

const prepareHeaders = async (headers) => {
    headers.set('X-CSRFToken', getCookie('csrftoken'));
    return headers;
};

export const baseQueriesApi = createApi({
    baseQuery: fetchBaseQuery({ prepareHeaders }),
    tagTypes: [],
    endpoints: () => ({}),
});
